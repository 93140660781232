//  ==========================================================================
//  Component: Search
//  ==========================================================================

.c-search {
    position: relative;
    min-width: 92px;
    max-width: 296px;
    height: 38px;
    margin-top: 20px;

    input[type="search"],
    button {
        float: $left;
        height: 100%;
        outline: 0;
        background-color: $color-background;
    }

    input[type="search"] {
        box-sizing: border-box;
        width: 100%;
        height: 100%;
        padding: 7px 10px;
        padding-#{$right}: 38px;
        border: 1px solid $color-foreground-60;

        &:hover {
            border-color: $color-foreground-80;
        }

        &:active,
        &:focus {
            border-color: $color-accent;
        }

        &::-ms-clear {
            display: none;
        }
    }

    button {
        position: absolute;
        top: 0;
        #{$right}: 0;
        width: 34px;
        height: 34px;
        margin: 2px 1px 1px;
        padding: 9px;
        transition: color 0.1s, background-color 0.1s;
        border: 0;

        &:hover {
            color: $color-accent;
        }

        &:active,
        &:focus {
            color: $color-background;
            background: $color-accent;
        }

        &:before {
            content: $glyph-search;
            text-indent: 0;
            font-size: 16px;
        }
    }
}