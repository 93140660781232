fluent-radio-group {
  --neutral-stroke-strong-rest: var(--type-primary);
  --neutral-fill-input-alt-rest: var(--component-fill);

  --neutral-fill-input-alt-hover: var(--component-fill);
  --neutral-stroke-strong-hover: var(--type-primary);

  --neutral-fill-input-alt-active: var(--component-fill);
  --neutral-stroke-strong-active: var(--type-primary);

  --neutral-foreground-rest: var(--type-primary);
  --accent-fill-rest: var(--primary);
  --accent-fill-hover: var(--primary-hover);

  --focus-stroke-outer: var(--type-primary);
}
