//*****************************************//
//******** VIDEO INDEXER TYPOGRAPHY *******//
//*****************************************//

// $headline-32-40
$headline-32-40-font-size: 32px;
$headline-32-40-line-height: 40px;
$headline-32-40-font-weight: 400;
$headline-32-40-font-style: normal;

// $title-h1-20-28
$title-h1-20-28-font-size: 20px;
$title-h1-20-28-line-height: 28px;
$title-h1-20-28-font-weight: 400;
$title-h1-20-28-font-style: normal;

// $title-h1-alt-20-28
$title-h1-alt-20-28-font-size: 20px;
$title-h1-alt-20-28-line-height: 28px;
$title-h1-alt-20-28-font-weight: 600;
$title-h1-alt-20-28-font-style: normal;

// $subheader-18-24
$subheader-18-24-font-size: 18px;
$subheader-18-24-line-height: 24px;
$subheader-18-24-font-weight: 600;
$subheader-18-24-font-style: normal;

// $title-h2-16-22
$title-h2-16-22-font-size: 16px;
$title-h2-16-22-line-height: 22px;
$title-h2-16-22-font-weight: 400;
$title-h2-16-22-font-style: normal;

// $title-h2-alt-16-22
$title-h2-alt-16-22-font-size: 16px;
$title-h2-alt-16-22-line-height: 22px;
$title-h2-alt-16-22-font-weight: 600;
$title-h2-alt-16-22-font-style: normal;

// $actions-14-20
$actions-14-20-font-size: 14px;
$actions-14-20-line-height: 20px;
$actions-14-20-font-weight: 400;
$actions-14-20-font-style: normal;

// $actions-alt-14-20
$actions-alt-14-20-font-size: 14px;
$actions-alt-14-20-line-height: 20px;
$actions-alt-14-20-font-weight: 600;
$actions-alt-14-20-font-style: normal;

// $body-13-16
$body-13-16-font-size: 13px;
$body-13-16-line-height: 16px;
$body-13-16-font-weight: 400;
$body-13-16-font-style: normal;

// $body-alt-1-13-16
$body-alt-1-13-16-font-size: 13px;
$body-alt-1-13-16-line-height: 16px;
$body-alt-1-13-16-font-weight: 600;
$body-alt-1-13-16-font-style: normal;

// $body-alt-2-13-16
$body-alt-2-13-16-font-size: 13px;
$body-alt-2-13-16-line-height: 16px;
$body-alt-2-13-16-font-weight: 400;
$body-alt-2-13-16-font-style: italic;

// $body-link-13-16
$body-link-13-16-font-size: 13px;
$body-link-13-16-line-height: 16px;
$body-link-13-16-font-weight: 400;
$body-link-13-16-font-style: underline;

// $body-combo-13-24
$body-combo-13-24-font-size: 13px;
$body-combo-13-24-line-height: 24px;
$body-combo-13-24-font-weight: 400;
$body-combo-13-24-font-style: normal;

// $body-paragraph-13-18
$body-paragraph-13-18-font-size: 13px;
$body-paragraph-13-18-line-height: 18px;
$body-paragraph-13-18-font-weight: 400;
$body-paragraph-13-18-font-style: normal;

// $function-12-16
$function-12-16-font-size: 12px;
$function-12-16-line-height: 16px;
$function-12-16-font-weight: 400;
$function-12-16-font-style: normal;

// $function-alt-12-16
$function-alt-12-16-font-size: 12px;
$function-alt-12-16-line-height: 16px;
$function-alt-12-16-font-weight: 600;
$function-alt-12-16-font-style: normal;

// $function-alt-12-14
$function-alt-12-14-font-size: 12px;
$function-alt-12-14-line-height: 14px;
$function-alt-12-14-font-weight: 600;
$function-alt-12-14-font-style: normal;

// $stamp-10-14
$stamp-10-14-font-size: 10px;
$stamp-10-14-line-height: 14px;
$stamp-10-14-font-weight: 400;
$stamp-10-14-font-style: normal;

// $stamp-alt-10-24
$stamp-alt-10-24-font-size: 10px;
$stamp-alt-10-24-line-height: 24px;
$stamp-alt-10-24-font-weight: 700;
$stamp-alt-10-24-font-style: normal;

// $body-13-18
$body-13-18-font-size: 13px;
$body-13-18-line-height: 18px;
$body-13-18-font-weight: 400;
$body-13-18-font-style: normal;

// $body-alt-1-13-18
$body-alt-1-13-18-font-size: 13px;
$body-alt-1-13-18-line-height: 18px;
$body-alt-1-13-18-font-weight: 600;
$body-alt-1-13-18-font-style: normal;

// $body-alt-2-13-18
$body-alt-2-13-18-font-size: 13px;
$body-alt-2-13-18-line-height: 18px;
$body-alt-2-13-18-font-weight: 400;
$body-alt-2-13-18-font-style: italic;

//*****************************************//
//************ TYPOGRAPHY MIXINS **********//
//*****************************************//

@mixin headline-32-40 {
  font-size: $headline-32-40-font-size;
  line-height: $headline-32-40-line-height;
  font-weight: $headline-32-40-font-weight;
  font-style: $headline-32-40-font-style;
}

@mixin title-h1-20-28 {
  font-size: $title-h1-20-28-font-size;
  line-height: $title-h1-20-28-line-height;
  font-weight: $title-h1-20-28-font-weight;
  font-style: $title-h1-20-28-font-style;
}

@mixin title-h1-alt-20-28 {
  font-size: $title-h1-alt-20-28-font-size;
  line-height: $title-h1-alt-20-28-line-height;
  font-weight: $title-h1-alt-20-28-font-weight;
  font-style: $title-h1-alt-20-28-font-style;
}

@mixin subheader-18-24 {
  font-size: $subheader-18-24-font-size;
  line-height: $subheader-18-24-line-height;
  font-weight: $subheader-18-24-font-weight;
  font-style: $subheader-18-24-font-style;
}

@mixin title-h2-16-22 {
  font-size: $title-h2-16-22-font-size;
  line-height: $title-h2-16-22-line-height;
  font-weight: $title-h2-16-22-font-weight;
  font-style: $title-h2-16-22-font-style;
}

@mixin title-h2-alt-16-22 {
  font-size: $title-h2-alt-16-22-font-size;
  line-height: $title-h2-alt-16-22-line-height;
  font-weight: $title-h2-alt-16-22-font-weight;
  font-style: $title-h2-alt-16-22-font-style;
}

@mixin actions-14-20 {
  font-size: $actions-14-20-font-size;
  line-height: $actions-14-20-line-height;
  font-weight: $actions-14-20-font-weight;
  font-style: $actions-14-20-font-style;
}

@mixin actions-alt-14-20 {
  font-size: $actions-alt-14-20-font-size;
  line-height: $actions-alt-14-20-line-height;
  font-weight: $actions-alt-14-20-font-weight;
  font-style: $actions-alt-14-20-font-style;
}

@mixin body-13-16 {
  font-size: $body-13-16-font-size;
  line-height: $body-13-16-line-height;
  font-weight: $body-13-16-font-weight;
  font-style: $body-13-16-font-style;
}

@mixin body-alt-1-13-16 {
  font-size: $body-alt-1-13-16-font-size;
  line-height: $body-alt-1-13-16-line-height;
  font-weight: $body-alt-1-13-16-font-weight;
  font-style: $body-alt-1-13-16-font-style;
}

@mixin body-alt-2-13-16 {
  font-size: $body-alt-2-13-16-font-size;
  line-height: $body-alt-2-13-16-line-height;
  font-weight: $body-alt-2-13-16-font-weight;
  font-style: $body-alt-2-13-16-font-style;
}

@mixin body-link-13-16 {
  font-size: $body-link-13-16-font-size;
  line-height: $body-link-13-16-line-height;
  font-weight: $body-link-13-16-font-weight;
  font-style: $body-link-13-16-font-style;
}

@mixin body-combo-13-24 {
  font-size: $body-combo-13-24-font-size;
  line-height: $body-combo-13-24-line-height;
  font-weight: $body-combo-13-24-font-weight;
  font-style: $body-combo-13-24-font-style;
}

@mixin body-13-18 {
  font-size: $body-13-18-font-size;
  line-height: $body-13-18-line-height;
  font-weight: $body-13-18-font-weight;
  font-style: $body-13-18-font-style;
}

@mixin body-alt-1-13-18 {
  font-size: $body-alt-1-13-18-font-size;
  line-height: $body-alt-1-13-18-line-height;
  font-weight: $body-alt-1-13-18-font-weight;
  font-style: $body-alt-1-13-18-font-style;
}

@mixin body-alt-2-13-18 {
  font-size: $body-alt-2-13-18-font-size;
  line-height: $body-alt-2-13-18-line-height;
  font-weight: $body-alt-2-13-18-font-weight;
  font-style: $body-alt-2-13-18-font-style;
}

@mixin body-paragraph-13-18 {
  font-size: $body-paragraph-13-18-font-size;
  line-height: $body-paragraph-13-18-line-height;
  font-weight: $body-paragraph-13-18-font-weight;
  font-style: $body-paragraph-13-18-font-style;
}

@mixin function-12-16 {
  font-size: $function-12-16-font-size;
  line-height: $function-12-16-line-height;
  font-weight: $function-12-16-font-weight;
  font-style: $function-12-16-font-style;
}

@mixin function-alt-12-16 {
  font-size: $function-alt-12-16-font-size;
  line-height: $function-alt-12-16-line-height;
  font-weight: $function-alt-12-16-font-weight;
  font-style: $function-alt-12-16-font-style;
}

@mixin function-alt-12-14 {
  font-size: $function-alt-12-14-font-size;
  line-height: $function-alt-12-14-line-height;
  font-weight: $function-alt-12-14-font-weight;
  font-style: $function-alt-12-14-font-style;
}

@mixin stamp-10-14 {
  font-size: $stamp-10-14-font-size;
  line-height: $stamp-10-14-line-height;
  font-weight: $stamp-10-14-font-weight;
  font-style: $stamp-10-14-font-style;
}

@mixin stamp-alt-10-24 {
  font-size: $stamp-alt-10-24-font-size;
  line-height: $stamp-alt-10-24-line-height;
  font-weight: $stamp-alt-10-24-font-weight;
  font-style: $stamp-alt-10-24-font-style;
}
