﻿// ==========================================================================
// License requirements
// ==========================================================================
/*! %versionBanner% | Copyright 2016 Microsoft Corporation | This software is based on or incorporates material from the files listed below (collectively, "Third Party Code"). Microsoft is not the original author of the Third Party Code. The original copyright notice and the license under which Microsoft received Third Party Code are set forth below together with the full text of such license. Such notices and license are provided solely for your information. Microsoft, not the third party, licenses this Third Party Code to you under the terms in which you received the Microsoft software or the services, unless Microsoft clearly states that such Microsoft terms do NOT apply for a particular Third Party Code. Unless applicable law gives you more rights, Microsoft reserves all other rights not expressly granted under such agreement(s), whether by implication, estoppel or otherwise.*/
@charset "UTF-8";

// ==========================================================================
// Utility mixins / functions
// ==========================================================================
@import 'utilities/package';

// ==========================================================================
// Config Variables
// ==========================================================================
@import 'config/package';

// ==========================================================================
// Document setup
// ==========================================================================
@import 'normalize';
@import 'foundation';

// ==========================================================================
// Grid
// ==========================================================================
@import 'grid';

// ==========================================================================
// Extensions
// ==========================================================================
@import 'extensions/animations';
@import 'extensions/clearfix';
@import 'extensions/floats';
@import 'extensions/print';
@import 'extensions/responsive';
@import 'extensions/screen_reader';
@import 'extensions/typography';
@import 'extensions/module_padding';

// ==========================================================================
// Components
// ==========================================================================
@import '../templates/components/action-trigger/styles/action_trigger';
@import '../templates/components/age-rating/styles/age_rating';
@import '../templates/components/auto-suggest/styles/auto_suggest';
@import '../templates/components/badge/styles/badge';
@import '../templates/components/breadcrumb/styles/breadcrumb';
@import '../templates/components/button/styles/button';
@import '../templates/components/call-to-action/styles/call_to_action';
@import '../templates/components/carousel/styles/carousel';
@import '../templates/components/checkbox/styles/checkbox';
@import '../templates/components/choice-summary/styles/choice_summary';
// @import '../templates/components/combo/styles/combo';
@import '../templates/components/content-placement/styles/content_placement';
// @import '../templates/components/content-toggle/styles/content_toggle';
@import '../templates/components/datetime-picker/styles/datetime_picker';
@import '../templates/components/dialog/styles/dialog';
@import '../templates/components/divider/styles/divider';
@import '../templates/components/drawer/styles/drawer';
@import '../templates/components/explicit/styles/explicit';
@import '../templates/components/feature/styles/feature';
@import '../templates/components/file/styles/file';
@import '../templates/components/flipper/styles/flipper';
@import '../templates/components/flyout/styles/flyout';
@import '../templates/components/universal-footer/styles/universal_footer';
@import '../templates/components/group/styles/group';
@import '../templates/components/heading/styles/heading';
@import '../templates/components/histogram/styles/histogram';
@import '../templates/components/hyperlink/styles/hyperlink';
@import '../templates/components/glyph/styles/glyph';
@import '../templates/components/image/styles/image';
@import '../templates/components/in-page-navigation/styles/in_page_navigation';
@import '../templates/components/label/styles/label';
@import '../templates/components/link-navigation/styles/link_navigation';
@import '../templates/components/list/styles/list';
@import '../templates/components/logo/styles/logo';
@import '../templates/components/metatext/styles/metatext';
@import '../templates/components/menu-item/styles/menu_item';
@import '../templates/components/menu/styles/menu';
@import '../templates/components/mosaic/styles/mosaic';
@import '../templates/components/pagination/styles/pagination';
@import '../templates/components/paragraph/styles/paragraph';
@import '../templates/components/password/styles/password';
@import '../templates/components/pivot/styles/pivot';
@import '../templates/components/mosaic-placement/styles/mosaic_placement';
@import '../templates/components/price/styles/price';
@import '../templates/components/progress/styles/progress';
@import '../templates/components/rating/styles/rating';
@import '../templates/components/radio/styles/radio';
@import '../templates/components/refine-item/styles/refine_item';
@import '../templates/components/refine-menu/styles/refine_menu';
@import '../templates/components/search/styles/search';
// @import '../templates/components/select-button/styles/select_button';
@import '../templates/components/select-menu/styles/select_menu';
@import '../templates/components/select/styles/select';
@import '../templates/components/sequence-indicator/styles/sequence_indicator';
// @import '../templates/components/slider/styles/slider';
@import '../templates/components/structured-list/styles/structured_list';
@import '../templates/components/supplemental-navigation/styles/supplemental_navigation';
@import '../templates/components/table/styles/table';
@import '../templates/components/textarea/styles/textarea';
@import '../templates/components/text-field/styles/text_field';
@import '../templates/components/toggle/styles/toggle';
@import '../templates/components/tooltip/styles/tooltip';
@import '../templates/components/track-list-placement/styles/track_list_placement';
// @import '../templates/components/universal-header/styles/universal_header';
@import '../templates/components/video/styles/video';

// ==========================================================================
// Module Items
// ==========================================================================
@import '../templates/modules/product-placement-item/styles/product_placement_item';
@import '../templates/modules/hero-item/styles/hero_item';
// ==========================================================================
// Modules
// ==========================================================================

@import '../templates/modules/additional-information/styles/additional_information';
// @import '../templates/modules/alert/styles/alert';
@import '../templates/modules/back-to-top/styles/back_to_top';
@import '../templates/modules/banner/styles/banner';
@import '../templates/modules/compare-chart/styles/compare_chart';
@import '../templates/modules/content-placement/styles/content_placement';
@import '../templates/modules/feature/styles/feature';
// @import '../templates/modules/feature-channel/styles/feature_channel';
@import '../templates/modules/group-call-to-action/styles/group_call_to_action';
@import '../templates/modules/hero/styles/hero';
@import '../templates/modules/in-page-navigation/styles/in_page_navigation';
@import '../templates/modules/link-navigation/styles/link_navigation';
@import '../templates/modules/media/styles/media';
// @import '../templates/modules/hyperlink-group/styles/hyperlink_group';
@import '../templates/modules/mosaic/styles/mosaic';
@import '../templates/modules/multi-column/styles/multi_column';
@import '../templates/modules/page-bar/styles/page_bar';
// @import '../templates/modules/ratings-and-reviews/styles/ratings_and_reviews';
@import '../templates/modules/refinements/styles/refinements';
@import '../templates/modules/rich-heading/styles/rich_heading';
@import '../templates/modules/product-detail-hero/styles/product_detail_hero';
@import '../templates/modules/live-gold-badge/styles/live_gold_badge';
@import '../templates/modules/product-placement/styles/product_placement';
@import '../templates/modules/search-help/styles/search_help';
@import '../templates/modules/search-results/styles/search_results';
@import '../templates/modules/social/styles/social';
@import '../templates/modules/supplemental-navigation/styles/supplemental_navigation';
@import '../templates/modules/system-requirements/styles/system_requirements';
