@import 'ngx-toastr/toastr';
@import '../accessibility/_accessibility';

.toast-error {
  background-image: none;
  background-color: var(--error-area-tint);

  &.toast {
    color: var(--bg-primary) !important;
  }

  i.i-vi-close-big {
    &:focus {
      outline: 1px solid var(--error-type);
      outline-offset: 4px;
    }
  }
}

.toast-bottom-full-width {
  color: var(--bg-primary);
  width: 100%;
}

.toast-container {
  .toast-success {
    background-image: none;
    background-color: var(--bg-primary);
    color: var(--type-primary);
  }
  .ngx-toastr {
    @include body-13-18();
    border-radius: 2px;
    padding: 12px;
    color: var(--type-primary);
    box-shadow: 0px 8px 10px var(--shadow) !important;
    &.toast-error {
      color: var(--error-type) !important;
    }
    i.i-vi-close-big {
      border: none;
      position: absolute;
      right: 12px;
      top: 12px;
      font-size: 16px;
      line-height: 18px;
      font-weight: 400;
    }
  }
  &.toast-bottom-full-width {
    .ngx-toastr {
      margin: 0;
      color: var(--bg-primary);
      width: 100%;
      border-radius: 0;
    }
  }
  &.toast-bottom-right {
    bottom: 20px;
  }
}

.vi-message {
  display: flex;
  i {
    align-self: start;
  }
  .vi-text {
    width: 100%;
    padding-left: 8px;
    padding-right: 18px;
  }
}

.vi-notification-card {
  display: grid;
  gap: 8px;
  i {
    font-size: 16px;
    padding-top: 2px;
  }
  i.i-vi-close-big {
    top: 12px !important;
    right: 12px !important;
  }
  .i-vi-info {
    color: var(--primary);
  }
  .i-vi-select {
    color: var(--sentiment-positive);
  }
  .i-vi-warning {
    color: var(--emotion-joy);
  }
  .i-vi-error {
    color: var(--error-type);
  }
  .notification-title {
    width: calc(100% - 18px);
    display: grid;
    grid-template-columns: 16px auto;
    column-gap: 8px;
    &.no-icon {
      grid-template-columns: auto;
    }
    .vi-text {
      line-height: 18px;
      font-size: 14px;
      font-weight: 600;
      color: var(--type-primary);
      overflow: hidden;
    }
    &.hide {
      display: none;
    }
  }
  .notification-message {
    display: grid;
    &.show-icon {
      padding-right: 16px;

      i {
        display: block;
      }
    }
    i {
      display: none;
    }
    .vi-text {
      font-size: 13px;
      padding: 0;
      color: var(--type-secondary);
      overflow: hidden;
      &.no-text {
        display: none;
      }
    }

    .link-break {
      display: block;
      padding-top: 8px;
    }
  }

  .notification-link {
    font-size: 14px;
    color: var(--primary);
  }
}
