//  ==========================================================================
//  Component: Call to action
//  ==========================================================================

a.c-call-to-action {
  @include apply-type(t8, false, true);
  display: inline-block;
  max-width: 100%;
  padding: set-direction-based-value(10px 40px 7px 24px, 10px 24px 7px 40px);
  overflow: hidden;
  border: 2px solid transparent;
  color: $color-background;
  background: $color-accent;
  line-height: 1.3;
  text-decoration: none;
  text-align: center;
  letter-spacing: 0.075em;
  white-space: nowrap;

  span {
    display: inline-block;
    max-width: 100%;
    text-overflow: clip;
    overflow: hidden;
  }

  &:after {
    position: absolute;
    display: inline-block;
    margin-#{$left}: 4px;
    margin-top: 1px;
    content: set-direction-based-value($glyph-chevron-right, $glyph-chevron-left);
    vertical-align: top;
  }

  &:hover,
  &:focus {
    background: flatten-overlay($color-accent, $color-foreground, 10%);
    border-color: $color-foreground-40;
  }

  &:focus {
    outline: 1px dashed $color-foreground;
  }

  &:hover {
    outline: none;
  }

  &:active {
    border-color: transparent;
    background: flatten-overlay($color-accent, $color-foreground, 30%);
  }

  &[aria-disabled='true'] {
    cursor: not-allowed;
    border-color: transparent;
    background: flatten-overlay($color-background, $color-accent, 20%);
    color: $color-foreground-20;
  }

  #{$theme-light} {
    color: $color-tint;
    background: $color-shade;

    &:hover,
    &:focus {
      background: flatten-overlay($color-tint, $color-shade, 80%);
      border-color: $color-shade;
      outline-color: $color-shade;
    }

    &:active {
      background: flatten-overlay($color-tint, $color-shade, 60%);
      border-color: transparent;
    }

    &[aria-disabled='true'] {
      background: flatten-overlay($color-tint, $color-shade, 20%);
      border-color: transparent;
      color: $color-shade-20;
    }
  }

  #{$theme-dark} {
    color: $color-shade;
    background: $color-tint;

    &:hover,
    &:focus {
      background: flatten-overlay($color-shade, $color-tint, 80%);
      border-color: $color-tint;
      outline-color: $color-shade;
    }

    &:active {
      background: flatten-overlay($color-shade, $color-tint, 60%);
      border-color: transparent;
    }

    &[aria-disabled='true'] {
      background: flatten-overlay($color-shade, $color-tint, 30%);
      border-color: transparent;
      color: $color-tint-20;
    }
  }

  &.f-secondary {
    color: $color-foreground;
    background: flatten-overlay($color-background, $color-foreground, 20%);

    &:hover,
    &:focus {
      border-color: $color-foreground-40;
    }

    &:active {
      border-color: transparent;
      background: flatten-overlay($color-background, $color-foreground, 40%);
    }

    &[aria-disabled='true'] {
      background: flatten-overlay($color-background, $color-foreground, 20%);
      border-color: transparent;
      color: $color-foreground-20;
    }

    #{$theme-light} {
      color: $color-shade;
      background: $color-transparent;
      border-color: $color-shade;

      &:hover,
      &:focus {
        background: flatten-overlay($color-tint, $color-shade, 20%);
        border-color: $color-shade;
      }

      &:focus {
        outline-color: $color-shade;
      }

      &:active {
        background: flatten-overlay($color-tint, $color-shade, 40%);
      }

      &[aria-disabled='true'] {
        background: flatten-overlay($color-tint, $color-shade, 20%);
        border-color: $color-shade-20;
        color: $color-shade-20;
      }
    }

    #{$theme-dark} {
      background: $color-transparent;
      color: $color-tint;
      border-color: $color-tint;

      &:hover,
      &:focus {
        background: flatten-overlay($color-shade, $color-tint, 30%);
        border-color: $color-tint;
      }

      &:focus {
        outline-color: $color-tint;
      }

      &:active {
        background: flatten-overlay($color-shade, $color-tint, 40%);
      }

      &[aria-disabled='true'] {
        background: flatten-overlay($color-shade, $color-tint, 30%);
        border-color: $color-tint-20;
        color: $color-tint-20;
      }
    }
  }
}
