//************************************//
//****** VIDEO INDEXER COLORS ********//
//************************************//

// Greens
$vi-green: #1abc9c;
$vi-hover-green: #00ad87;
$vi-press-green: #009c74;
$access-green: #12866e;
$color-new-vi-press-green: rgba(12, 110, 89, 1);
$color-new-vi-hover-green: rgba(15, 122, 100, 1);
$color-bg-primary: rgba(245, 245, 245, 1);
$color-avatar-01: rgba(3, 131, 135, 1);

// #000 opacity
$black-6-5: rgba(0, 0, 0, 0.065);
$black-12: rgba(0, 0, 0, 0.12);
$black-26: rgba(0, 0, 0, 0.26);
$black-38: rgba(0, 0, 0, 0.38);
$black-54: rgba(0, 0, 0, 0.54);
$black-62: rgba(0, 0, 0, 0.62);
$black-74: rgba(0, 0, 0, 0.74);
$black-87: rgba(0, 0, 0, 0.87);

// #FFF opacity (for dark bg)
$white-38: rgba(255, 255, 255, 0.38);
$white-74: rgba(255, 255, 255, 0.74);
$white: rgba(255, 255, 255, 100);

// Emotion colors
$emotion-neutral: #828282;
$emotion-sadness: #213bd1;
$emotion-joy: #cc4e00;
$emotion-anger: #eb001b;
$emotion-fear: #861cad;

// Sentiment colors
$sentiment-negative: #eb001b;
$sentiment-positive: #12866e;

// Play status
$play-status: #bde0ff;
$play-status-04: rgba(189, 224, 255, 0.4);

// Progress & Validation
$error-red: #d02e00;
$error-red-text: #a80000;
$error-red-tint: #fde7e9;
$warning-yellow-tint: #fff4ce;

// NOT EXPORTED BY US - needs to remove
$black-35: rgba(0, 0, 0, 0.35);
$black-0: rgba(0, 0, 0, 0);
$black-04: rgba(0, 0, 0, 0.04);
$black-0-2: rgba(0, 0, 0, 0.2);
$white-solid: rgba(255, 255, 255, 255);
$yellow: #ffff00;

// Components & Actions
$vi-box-shadow: rgba(0, 0, 0, 0.1);
$bg-primary: #fafafa;
$dropdown-item-separator: #eaeaea;
$side-menu-background: #f3f3f3;

// AMP
$video-actions: #ffffff;
$video-buffer: #ffffffc2;
$video-menu-bg: rgba(0, 0, 0, 0.9);
$video-action-hover: rgba(255, 255, 255, 0.12);
$video-action-press: rgba(255, 255, 255, 0.22);
