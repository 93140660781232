// Main
svg {
  font-family: 'Segoe UI', 'Arial';
  font-display: swap;
  font-size: 12px;
  -webkit-user-select: none;
  opacity: 0;
  transition: opacity 0.4s;
  -moz-transition: opacity 0.4s;
  -o-transition: opacity 0.4s;
  -webkit-transition: opacity 0.4s;
}

// Components
// Tooltip
.tooltip {
  opacity: 0;
  fill: var(--dividers-alt);
  &:hover {
    cursor: pointer;
    user-select: none;
  }
  text {
    font-size: 0.85em;
    fill: var(--type-primary);
  }
  @media screen and (-ms-high-contrast: white-on-black) {
    stroke-width: 1px;
    stroke: white;
  }
}

// Rect
rect {
  cursor: pointer;
  &.selected {
    fill: var(--highlight);
  }
  &:focus {
    fill: var(--type-disabled) !important;
    outline: none;
  }
}

// Buffer
.buffer-progress {
  transition: transform 0.1s;
  -moz-transition: transform 0.1s;
  -o-transition: transform 0.1s;
  -webkit-transition: transform 0.1s;
  opacity: 0;
  &:hover {
    opacity: 1;
  }
}

.bar {
  fill: var(--secondary-fill-hover);
  @media screen and (-ms-high-contrast: white-on-black) {
    stroke-width: 1px;
    stroke: white;
  }
}

.buffer {
  opacity: 0;
  fill: var(--type-disabled-alt);
}

.show {
  opacity: 1;
}

// Progress
.progress {
  fill: var(--play-status-alt);
  display: none;
  width: 100%;
}

.progress-container {
  fill: var(--component-fill);
}

// Animations
.transition {
  transform-origin: left;
  transition: transform 0.2s, opacity 0.2s, x 0.2s, width 0.2s;
  -moz-transition: transform 0.2s, opacity 0.2s, x 0.2s, width 0.2s;
  -o-transition: transform 0.2s, opacity 0.2s, x 0.2s, width 0.2s;
  -webkit-transition: transform 0.2s, opacity 0.2s, x 0.2s, width 0.2s;
}

svg {
  // Color Classes
  .positive {
    fill: var(--sentiment-positive);

    text {
      fill: var(--bg-secondary);
    }
  }

  .joy {
    fill: var(--emotion-joy);
    text {
      fill: var(--bg-secondary);

      &:after {
        content: 'hello';
      }
    }
  }
  .fear {
    fill: var(--emotion-fear);
    text {
      fill: var(--bg-secondary);
    }
  }
  .sad {
    fill: var(--emotion-sadness);
    text {
      fill: var(--bg-secondary);
    }
  }
  .anger {
    fill: var(--emotion-anger);
    text {
      fill: var(--bg-secondary);
    }
  }
  .negative {
    fill: var(--sentiment-negative);
    text {
      fill: var(--bg-secondary);
    }
  }
  .default {
    fill: var(--type-primary);
    text {
      fill: var(--bg-secondary);
    }
    @media screen and (-ms-high-contrast: white-on-black) {
      fill: var(--bg-secondary);
      text {
        stroke: var(--type-primary);
      }
    }
  }
}
