a.c-call-to-action {
    &.f-lightweight {
        border: 1px solid transparent;
        background: transparent;
    }
}

a.c-call-to-action, button.c-call-to-action {
  letter-spacing: 0;
}

a.c-call-to-action:focus, a.c-call-to-action:hover {
    color: #12866e;
}

a.c-call-to-action:after, button.c-call-to-action:after {
    transition: all 200ms ease-in-out;
}

a.c-call-to-action:not(.glyph-play):after, button.c-call-to-action:not(.glyph-play):after {
    font-size: 11px;
    margin-top: 3px;
    margin-left: 3px;
    display: inline-block;
    position: relative;
    right: 0;
    content: "";
    vertical-align: top;
}

a.c-call-to-action.f-lightweight:focus:not(.x-hidden-focus), button.c-call-to-action.f-lightweight:focus:not(.x-hidden-focus) {
    color: $access-green;
    background: white;
    span {
        box-shadow: none;
    }
}

a.c-call-to-action.f-lightweight:hover span, button.c-call-to-action.f-lightweight:hover span {
    text-decoration: underline;
}

a.c-hyperlink:not(.f-image) {
    border: 1px solid transparent;
}

a.c-hyperlink:not(.f-image):focus:not(.x-hidden-focus), a.c-hyperlink:not(.f-image):hover {
    color: $access-green;
    background: white;
    span {
        box-shadow: none;
    }
}

.c-glyph:before, .c-glyph:after, .c-glyph:hover:before, .c-glyph:hover:after {
    text-decoration: none;
}

/* Missing style in main mwf.scss */
input.c-text-field[type="password"] {
    position: relative;
    outline: 2px solid transparent;
    border: 0;
    display: block;
    width: 296px;
    height: 36px;
    margin-top: 20px;
    padding: 7px 10px;
    border: 1px solid var(--component-stroke);
    background: var(--component-fill);
}


[class^="m-persona"] {
    padding: 0;
    display: table;

    >div {
        float: left;
        padding-right: 12px;

        picture {
            position: relative;
            display: block;
            border-radius: 50%;

            .c-image {
                outline: 0;
                position: absolute;
                top: 50%;
                left: 50%;
                -ms-transform: translate(-50%,-50%);
                transform: translate(-50%,-50%);
            }
        }
    }
}
