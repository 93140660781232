//  ==========================================================================
//  Module: Rich heading
//  ==========================================================================

.m-rich-heading {
    display: block;
    height: 200px;
    position: relative;
    padding-top: 0;

    &.f-image {
        width: 100%;
        height: 200px;
        background: $color-shade;
        color: $color-tint;
    }

    & > div {
        position: relative;
        width: 50%;
        float: $left;
    }

    & > picture {
        &.c-image {
            img {
                height: 200px;
                width: 400px;
                position: absolute;
                #{$right}: 0;
            }

            &:after {
                content: "";
                position: absolute;
                z-index: map-get($z-index, default);
                top: 0;
                #{$right}: 285px;
                width: 115px;
                height: 200px;
                background: linear-gradient(to $left, $color-transparent 0, $color-shade 100%);
            }

            @media only screen and (max-width: $vp1-max) {
                &:after {
                    #{$right}: 0;
                    #{$left}: 0;
                    width: 100%;
                    background: linear-gradient(to $left, $color-transparent 0, $color-shade 80%);
                }

                &:before {
                    background: $color-transparent;
                }
            }
        }
    }

    & .c-paragraph-3 {
        @include apply-maxlines(2, p3, true);
    }

    & .c-heading {
        @include apply-maxlines(2, h3, true);
    }

    &#{$theme-light-selector} {
        &.f-brand-neutral-10 {
            background: $color-brand-neutral-10;
        }

        &.f-brand-neutral-20 {
            background: $color-brand-neutral-20;
        }

        &.f-brand-neutral-30 {
            background: $color-brand-neutral-30;
        }
    }

    &#{$theme-dark-selector} {
        &.f-accent {
            background: $color-accent;
        }

        &.f-brand-neutral-45 {
            background: $color-brand-neutral-45;
        }

        &.f-brand-neutral-55 {
            background: $color-brand-neutral-55;
        }

        &.f-brand-neutral-65 {
            background: $color-brand-neutral-65;
        }

        &.f-brand-neutral-75 {
            background: $color-brand-neutral-75;
        }

        &.f-brand-neutral-80 {
            background: $color-brand-neutral-80;
        }

        &.f-brand-neutral-90 {
            background: $color-brand-neutral-90;
        }

        &.f-brand-neutral-100 {
            background: $color-brand-neutral-100;
        }
    }

    & .c-logo {
        position: absolute;
        #{$right}: 48px;
        width: 200px;
        height: 130px;
        margin-top: 32px;
        margin-bottom: 32px;

        > .c-image {
            width: 100%;
            max-width: 200px;
            max-height: 130px;
        }
    }

    @media only screen and (max-width: $vp3-max) {
        & .c-heading {
            @include apply-type(t4, false, true);
            @include apply-maxlines(2, t4, true);
        }

        & .c-logo {
            #{$right}: 36px;
        }
    }

    @media only screen and (max-width: $vp2-max) {
        & > div {
            width: 100%;
            float: none;
        }

        & .c-logo,
        & .c-paragraph-3 {
            display: none;
        }

        & .c-heading {
            padding: 8px;
        }

        &#{$theme-dark-selector},
        &#{$theme-light-selector} {
            height: auto;
        }

        & > picture {
            &.c-image {
                z-index: map-get($z-index, below);

                & + div {
                    height: 200px;

                    & .c-heading {
                        position: absolute;
                        bottom: 40px;
                        z-index: map-get($z-index, neutral);
                    }
                }

                &:before {
                    content: "";
                    position: absolute;
                    z-index: map-get($z-index, neutral);
                    top: 0;
                    #{$right}: 0;
                    #{$left}: 0;
                    bottom: 0;
                    background: $color-shade-40;
                }
            }
        }
    }

    @media only screen and (max-width: $vp1-max) {
        & > picture {
            &.c-image {
                &:after {
                    #{$right}: 0;
                    #{$left}: 0;
                    width: 100%;
                    background: linear-gradient(to $left, $color-transparent 0, $color-shade 80%);
                }

                &:before {
                    background: $color-transparent;
                }
            }
        }
    }
}