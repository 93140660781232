// ==========================================================================
// glyph font definition
// ==========================================================================
$font-family-glyphs:         "MWF-MDL2";

// ==========================================================================
// glyph config set
// ==========================================================================
$glyph-arrow-htmllegacy-mirrored: "\e0ae";
$glyph-arrow-htmllegacy: "\e0d5";
$glyph-global-nav-button: "\e700";
$glyph-chevron-down: "\e70d";
$glyph-chevron-up: "\e70e";
$glyph-edit: "\e70f";
$glyph-add: "\e710";
$glyph-cancel: "\e711";
$glyph-more: "\e712";
$glyph-settings: "\e713";
$glyph-mail: "\e715";
$glyph-people: "\e716";
$glyph-phone: "\e717";
$glyph-zoom: "\e71e";
$glyph-zoom-out: "\e71f";
$glyph-microphone: "\e720";
$glyph-search: "\e721";
$glyph-camera: "\e722";
$glyph-forward: "\e72a";
$glyph-back: "\e72b";
$glyph-refresh: "\e72c";
$glyph-share: "\e72d";
$glyph-favorite-star: "\e734";
$glyph-favorite-star-fill: "\e735";
$glyph-remove: "\e738";
$glyph-checkbox-composite: "\e73a";
$glyph-checkbox-indeterminate: "\e73c";
$glyph-check-mark: "\e73e";
$glyph-back-to-window: "\e73f";
$glyph-full-screen: "\e740";
$glyph-up: "\e74a";
$glyph-down: "\e74b";
$glyph-delete: "\e74d";
$glyph-mute: "\e74f";
$glyph-volume: "\e767";
$glyph-play: "\e768";
$glyph-pause: "\e769";
$glyph-chevron-left: "\e76b";
$glyph-chevron-right: "\e76c";
$glyph-contact: "\e77b";
$glyph-slideshow: "\e786";
$glyph-calendar: "\e787";
$glyph-rotate: "\e7ad";
$glyph-warning: "\e814";
$glyph-shopping-cart: "\e7bf";
$glyph-flag: "\e7c1";
$glyph-page: "\e7c3";
$glyph-half-star-left: "\e7c6";
$glyph-half-star-right: "\e7c7";
$glyph-incident-triangle: "\e7ba";
$glyph-view: "\e890";
$glyph-previous: "\e892";
$glyph-next: "\e893";
$glyph-clear: "\e894";
$glyph-sync: "\e895";
$glyph-download: "\e896";
$glyph-go: "\e8ad";
$glyph-shuffle: "\e8b1";
$glyph-calendar-day: "\e8bf";
$glyph-calendar-week: "\e8c0";
$glyph-copy: "\e8c8";
$glyph-important: "\e8c9";
$glyph-sort: "\e8cb";
$glyph-font-decrease: "\e8e7";
$glyph-font-increase: "\e8e8";
$glyph-bulleted-list: "\e8fd";
$glyph-world: "\e909";
$glyph-accept: "\e8fb";
$glyph-music-album: "\e93c";
$glyph-info: "\e946";
$glyph-chevron-down-small: "\e96e";
$glyph-chevron-left-small: "\e96f";
$glyph-bulleted-list-mirrored: "\ea42";
$glyph-go-mirrored: "\ea4f";
$glyph-heart-fill: "\eb52";
$glyph-edit-mirrored: "\eb7e";
$glyph-holo-lens: "\ec94";
$glyph-qrcode: "\ed14";
$glyph-calendar-mirrored: "\ed28";