//  ==========================================================================
//  Component: Flyout
//  ==========================================================================

.c-flyout {
    @include apply-type(t7);
    position: absolute;
    background: $color-background;
    border: 1px solid $color-foreground-20;
    min-width: 128px;
    max-width: 296px;
    padding: 12px;
    z-index: map-get($z-index, flyout);

    &[aria-hidden="true"] {
        display: none;
    }

    &[aria-hidden="false"] {
        display: block;
    }

    p {
        padding: 0;
    }

    button {
        float: $right;
    }

    &:focus {
        outline: none;
    }
}