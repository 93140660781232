//  ==========================================================================
//  Component: Product placement
//  ==========================================================================

/*Use m-product-placement-item,
  c-product placement depricated as of v.1.6.0

  TODO: Remove ALL instances on v2.0.0*/
.m-product-placement-item,
.c-product-placement {
    @include left-right-padding($add-0);
    padding-top: 0;
    margin-#{$right}: 24px;
    margin-top: 12px;

    > a {
        @include clearfix();
        display: block;
        width: 100%;
        height: 100%;

        > picture img,
        .c-image {
            outline: 1px solid $color-foreground-10;
        }

        &:focus {
            outline: 1px dashed $color-foreground-60;
        }

        &:hover {
            .c-heading,
            .c-subheading {
                text-decoration: underline;
            }
        }

        &:hover,
        &:focus {
            > picture img,
            .c-image {
                outline: 1px solid $color-foreground-60;
            }
        }
    }

    picture {
        &,
        img,
        .c-image {
            display: inline-block;
        }
    }

    .c-badge {
        margin-top: 12px;
        margin-bottom: 4px;

        & + .c-heading {
            padding-top: 0;
        }
    }

    .c-heading {
        @include apply-type(t7, false, true);
        @include apply-maxlines(1, t7, true);
        padding-top: 8px;
        font-weight: $font-weight-normal;
    }

    .c-paragraph {
        @include apply-type-component(p3);
        @include apply-maxlines(3, p3, true);
        color: $color-foreground-60;
    }

    &.context-accessory,
    &.context-device,
    &.context-artist {
        picture img,
        .c-image {
            outline: none;
        }

        .f-default-image {
            outline: none;
        }
    }

    &.context-artist {
        picture img,
        .c-image {
            border-radius: 50%;
        }

        .f-default-image {
            border-radius: 50%;

            picture img,
            .c-image {
                border-radius: 0;
            }
        }

        &.f-size-medium > a > div {
            text-align: center;
            padding: 0 12px;
        }
    }

    &.context-tvshow {
        picture + div,
        .f-default-image + div {
            padding: 5px;
            height: 62px;
            width: 100%;
        }
    }

    .f-default-image {
        background-color: $color-foreground-20;
        outline: 1px solid $color-foreground-10;

        &:focus {
            outline: 1px dashed $color-foreground-60;
        }

        &:hover,
        &:focus {
            outline: 1px solid $color-foreground-60;
        }

        picture {
            position: relative;
            width: 100%;
            height: 100%;

            .c-image {
                outline: 0;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }

            &:focus {
                outline: none;
            }
        }
    }

    &.f-size-small {
        width: 358px;

        .c-badge {
            // Badges should never show on small viewports
            display: none;
        }

        picture {
            float: $left;
            margin-#{$right}: 12px;
        }

        .f-default-image {
            width: 72px;
            height: 72px;
            float: $left;
            margin-#{right}: 12px;
        }

        &.context-movie {
            .f-default-image {
                width: 72px;
                height: 103px;
            }
        }
    }

    &.f-size-medium {
        width: 167px;

        > a > div {
            padding-#{$right}: 24px;
        }

        .f-default-image {
            width: 167px;
            height: 167px;
            padding-#{$right}: 0;

            + div {
                padding-#{$right}: 24px;
            }
        }

        &.context-movie {
            .f-default-image {
                width: 167px;
                height: 229px;
            }
        }
    }

    &.f-size-large {
        width: 358px;

        > a > div {
            padding-#{$right}: 24px;
        }

        .f-default-image {
            width: 358px;
            height: 300px;
            padding-#{$right}: 0;

            + div {
                padding-#{$right}: 24px;
            }
        }
    }

    &.f-size-extra-large {
        width: 740px;

        > a > div {
            padding-#{$right}: 48px;
        }

        .f-default-image {
            width: 740px;
            height: 416px;
            padding-#{$right}: 0;

            + div {
                padding-#{$right}: 48px;
            }
        }
    }

    &.f-size-large,
    &.f-size-extra-large {
        .c-heading {
            @include apply-type(t5, false, true);
            @include apply-maxlines(2, t5);
            font-weight: $font-weight-semilight;
        }
    }

    @media only screen and (max-width: $vp1-max) {
        &.f-size-large {
            width: 272px;

            .f-default-image {
                width: 272px;
                height: 272px;
            }
        }
    }
}