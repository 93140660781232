@import './vars';

.vi-tooltip {
  position: absolute;
  background: $tooltip-background-color;
  padding: 6px 6px;
  font-family: arial;
  font-size: 12px;
  opacity: 1;
  color: white;
  top: calc(100% + 5px);
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  min-width: 90px;
  line-height: 16px;
  z-index: 999;

  &.right-side {
      left: auto;
  }

  &.left-side {
      left: 0;
      transform: none;
  }
}

@mixin default-accessibility-focus {
  outline: 1px var(--type-primary) solid;
  border: none;
}

@mixin default-accessibility-focus-2 {
  outline: 2px var(--type-primary) solid;
  border: none;
}

@mixin dashed-accessibility-focus-white {
  outline: dashed 1px $white;
}

@mixin dashed-accessibility-focus-black {
  outline: dashed 1px var(--type-primary);
}
