// /* ==========================================================================
//  Define Breakpoints
//  ============================================================================ */

$max-content-width: 1600px;
$min-content-width: 320px;

// /* ==========================================================================
//  Default Module Padding Values at responsive breakpoints
//  ============================================================================ */
$add-0: 0;
$add-12: 12px;
$add-24: 24px;
$add-36: 36px;
$add-48: 48px;