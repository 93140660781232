//  ==========================================================================
//  Component: Button
//  ==========================================================================
#{$migration-button}
button.c-button,
a.c-button[role="button"] {
    @include apply-type(t7);
    max-width: 374px;
    min-width: 120px;
    display: inline-block;
    margin-top: 12px;
    padding: 9px 12px 10px;
    background-color: $color-foreground-20;
    color: $color-foreground;
    border: solid 1px transparent;
    overflow: hidden;

    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: bottom;

    &:focus {
        outline-color: $color-foreground;
    }

    &:hover,
    &:focus {
        border-color: $color-foreground-40;
    }

    &:active {
        background-color: $color-foreground-40;
    }

    &[disabled] {
        color: $color-foreground-20;
        background-color: $color-foreground-20;
        cursor: not-allowed;
    }

    #{$theme-light} {
        background-color: transparent;
        color: $color-shade;
        border-color: $color-shade;

        &:focus {
            background-color: $color-shade-20;
            outline-color: $color-shade;
        }

        &:hover {
            background-color: $color-shade-20;
        }

        &:active {
            background-color: $color-shade-40;
        }

        &[disabled] {
            color: $color-shade-20;
            background-color: $color-shade-20;
            border-color: $color-shade-20;
        }
    }

    #{$theme-dark} {
        background-color: transparent;
        color: $color-tint;
        border-color: $color-tint;

        &:focus {
            background-color: $color-tint-20;
            outline-color: $color-tint;
        }

        &:hover {
            background-color: $color-tint-20;
        }

        &:active {
            background-color: $color-tint-40;
        }

        &[disabled] {
            color: $color-tint-20;
            background-color: $color-tint-20;
            border-color: $color-tint-20;
        }
    }
    &.f-lightweight {
        @include light-button;

        &:focus:not(.x-hidden-focus) {
            @media screen and (-ms-high-contrast: active) {
                outline: 1px dashed ButtonText;
            }
        }
    }
}

#{$migration-button-primary}
button.c-button[type="submit"] {
    @include primary-button();
}