//  ==========================================================================
//  Component: Progress
//  ==========================================================================

progress.c-progress {

    &[value] {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        border: 0;
        min-width: 296px;
        height: 4px;
    }

    &::-webkit-progress-bar {
        background: $color-foreground-20;
    }

    &::-webkit-progress-value {
        background: $color-accent;
    }

    &::-moz-progress-bar {
        background: $color-accent;
    }

    & > [role="progressbar"] {
        display: block;
        position: relative;
        background: $color-foreground-20;
        min-width: 296px;
        width: 100%;
        height: 4px;

        & > span {
            display: block;
            position: relative;
            height: 100%;
            background: $color-accent;
            color: $color-accent;
        }
    }
}

.c-progress {
    display: block;
    margin-top: 12px;

    &.f-indeterminate-regional {
        position: relative;
        min-width: 296px;
        width: 100%;

        & span {
            position: absolute;
            display: inline-block;
            opacity: 0;
            animation: dots 4s infinite;

            &:after {
                content: "";
                position: absolute;
                display: inline-block;
                background: $color-accent;
                width: 5px;
                height: 5px;
                border-radius: 50%;
            }

            &:nth-child(1) {
                animation-delay: 0.05s;

                &:after {
                    left: -10px;
                }
            }

            &:nth-child(2) {
                animation-delay: 0.1s;

                &:after {
                    left: -20px;
                }
            }

            &:nth-child(3) {
                animation-delay: 0.15s;

                &:after {
                    left: -30px;
                }
            }

            &:nth-child(4) {
                animation-delay: 0.2s;

                &:after {
                    left: -40px;
                }
            }

            &:nth-child(5) {
                animation-delay: 0.25s;

                &:after {
                    left: -50px;
                }
            }
        }

        @keyframes dots {

            0%,
            20% {
                left: 0;
                animation-timing-function: ease-out;
                opacity: 0;
            }

            25% {
                opacity: 1;
            }

            35% {
                left: 45%;
                animation-timing-function: linear;
            }

            65% {
                left: 55%;
                animation-timing-function: ease-in;
            }

            75% {
                opacity: 1;
            }

            80%,
            100% {
                left: 100%;
                opacity: 0;
            }
        }
    }

    &.f-indeterminate-local {
        position: relative;

        & span {
            position: absolute;
            transform: rotate(225deg);
            animation: orbit 5.5s infinite;

            &:after {
                content: '';
                position: absolute;
                border-radius: 100%;
                background: $color-accent;
            }

            &:nth-child(2) {
                animation-delay: 240ms;
            }

            &:nth-child(3) {
                animation-delay: 480ms;
            }

            &:nth-child(4) {
                animation-delay: 720ms;
            }

            &:nth-child(5) {
                animation-delay: 960ms;
            }
        }

        &.f-progress-large {
            width: 60px;
            height: 60px;

            & span {
                width: 60px;
                height: 60px;

                &:after {
                    width: 6px;
                    height: 6px;
                }
            }
        }

        &.f-progress-small {
            width: 20px;
            height: 20px;

            & span {
                width: 25px;
                height: 25px;

                &:after {
                    width: 3px;
                    height: 3px;
                }
            }
        }

        @keyframes orbit {
            0% {
                transform: rotate(225deg);
                opacity: 1;
                animation-timing-function: ease-out;
            }

            7% {
                transform: rotate(345deg);
                animation-timing-function: linear;
            }

            30% {
                transform: rotate(455deg);
                animation-timing-function: ease-in-out;
            }

            39% {
                transform: rotate(690deg);
                animation-timing-function: linear;
            }

            70% {
                transform: rotate(815deg);
                opacity: 1;
                animation-timing-function: ease-out;
            }

            75% {
                transform: rotate(945deg);
                animation-timing-function: ease-out;
            }

            76% {
                transform: rotate(945deg);
                opacity: 0;
            }

            100% {
                transform: rotate(945deg);
                opacity: 0;
            }
        }
    }
}