// @nilogi Adding central location for animation keyframes
@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    1% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}