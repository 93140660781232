.x-type-center {
    text-align: center !important;
}

.x-type-right {
    text-align: $right !important;
}

.x-type-left {
    text-align: $left !important;
}