//  ==========================================================================
//  Component: Text field
//  ==========================================================================

#{$migration-text-field}
input.c-text-field[type="text"] {
    display: block;
    width: 276px;
    height: 36px;
    margin-top: 20px;
    padding: 7px 10px;
    outline: 0;
    border: 1px solid var(--component-stroke);
    background: var(--component-fill);

    &.f-small {
        width: 88px;
    }

    &.f-flex {
        width: 100%;
        min-width: 88px;
        max-width: 400px;
    }

    &:active,
    &:focus {
        border-color: var(--type-primary);
    }

    &[disabled] {
        cursor: not-allowed;
        color: var(--type-disabled);
        border-color: var(--type-disabled);
    }

    &[readonly] {
        border: 1px solid $color-foreground-60;
        background: flatten-overlay($color-background, $color-foreground, 10%);
    }
}