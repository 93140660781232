.m-product-placement {
    padding-top: 12px;

    .c-hyperlink[aria-hidden="true"] {
        visibility: hidden;
    }

    &.f-artist {
        a > div {
            height: inherit;
        }
    }

    .c-carousel {
        .c-flipper {
            /*
            * Use .f-previous and .f-next
            * .f-left and f-right deprecated v1.3.0
            */
            &.f-left,
            &.f-previous {
                #{$left}: -24px;
            }

            &.f-right,
            &.f-next {
                #{$right}: -24px;
            }
        }
    }

    @media only screen and (max-width: $vp2-max){
        .c-carousel {
            .c-flipper {
                /*
                * Use .f-previous and .f-next
                * .f-left and f-right deprecated v1.3.0
                */
                &.f-left,
                &.f-previous {
                    background-color: transparent;
                }

                &.f-right,
                &.f-next {
                    background-color: transparent;
                }
            }
        }
    }
}