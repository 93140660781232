@import 'core/styles/mwf_core';
@import 'theme';
@import 'videoindexer';
@import 'segoeui';
@import 'mwf-overrides';
@import 'material-overrides';
@import 'themes/themes';
@import '../common/modules/utils/utils';
@import '../common/modules/insights/components/svg-progress-chart/styles/svgProgress.style';
@import '../common/modules/utils/notification';

body {
  margin: 0;
  overflow-x: hidden;
  min-width: $responsive-mini;
  font-family: $font-family-vi;
}

@include scrollbars(0.3em, var(--type-disabled), transparent);

.loading-bg {
  background: linear-gradient(270deg, $white, $black-26) !important;
  background-size: 400% 400% !important;

  -webkit-animation: AnimationName 2s ease infinite;
  -moz-animation: AnimationName 2s ease infinite;
  animation: AnimationName 2s ease infinite;

  @-webkit-keyframes AnimationName {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
  @-moz-keyframes AnimationName {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
  @keyframes AnimationName {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
}

.rotating {
  -webkit-animation: spin 1s linear infinite;
  -moz-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
  @-moz-keyframes spin {
    100% {
      -moz-transform: rotate(360deg);
    }
  }
  @-webkit-keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
    }
  }
  @keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
}

* {
  //outline: 1px dashed red!important;
}

i {
  &.joy {
    color: $emotion-joy;
  }
  &.sad {
    color: var(--emotion-sadness);
  }
  &.fear {
    color: $emotion-fear;
  }
  &.anger {
    color: $emotion-anger;
  }
}

.fallback-msg {
  display: none;
  color: var(--type-secondary);
  &.show {
    display: block;
  }
}

span.highlight {
  background-color: rgba($vi-green, 0.2);
  padding: 1px 2px;
  color: $access-green;
}

.cdk-live-announcer-element {
  position: absolute !important;
  overflow: hidden !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  width: 1px !important;
  height: 1px !important;
  border: none !important;
  padding: 0 !important;
  margin: 0 !important;
}

.truncate {
  width: 100%;
  white-space: nowrap !important;
  overflow: hidden;
  text-overflow: ellipsis;
}
