:root.vi.darkLegacy {
  --highlight: rgba(26, 188, 156, 1);
  --primary: rgba(26, 188, 156, 1);
  --primary-hover: rgba(34, 222, 185, 1);
  --primary-press: rgba(26, 188, 156, 1);
  --primary-disable: rgba(255, 255, 255, 0.12);
  --secondary-fill: rgba(26, 26, 26, 1);
  --secondary-fill-hover: rgba(255, 255, 255, 0.08);
  --secondary-fill-press: rgba(255, 255, 255, 0.12);
  --secondary-stroke: rgba(255, 255, 255, 0.54);
  --component-fill: rgba(36, 36, 36, 1);
  --component-stroke: rgba(255, 255, 255, 0.62);
  --component-stroke-alt: rgba(255, 255, 255, 0.76);
  --play-status: rgba(119, 189, 242, 0.4);
  --play-status-alt: rgba(119, 189, 242, 1);
  --editor-highlight: rgba(26, 188, 156, 0.2);
  --editor-highlight-alt: rgba(26, 188, 156, 0.1);
  --thumb-overlay: rgba(0, 0, 0, 0.32);
  --header-actions: rgba(255, 255, 255, 1);
  --header-hovers: rgba(255, 255, 255, 0.38);
  --header-hovers-alt: rgba(255, 255, 255, 0.76);
  --header-bg: rgba(51, 51, 51, 1);
  --bg-dialog: rgba(0, 0, 0, 0.62);
  --dividers: rgba(255, 255, 255, 0.08);
  --dividers-alt: rgba(255, 255, 255, 0.12);
  --dividers-panel: rgba(18, 18, 18, 1);
  --bg-primary: rgba(26, 26, 26, 1);
  --bg-secondary: rgba(36, 36, 36, 1);
  --bg-tertiary: rgba(18, 18, 18, 1);
  --bg-access: rgba(0, 0, 0, 0.76);
  --type-primary: rgba(255, 255, 255, 1);
  --type-secondary: rgba(255, 255, 255, 0.76);
  --type-disabled: rgba(255, 255, 255, 0.38);
  --type-disabled-alt: rgba(255, 255, 255, 0.22);
  --white-placeholder: rgba(255, 255, 255, 0.76);
  --emotion-joy: rgba(242, 124, 74, 1);
  --emotion-fear: rgba(192, 125, 255, 1);
  --emotion-sadness: rgba(77, 157, 255, 1);
  --emotion-anger: rgba(255, 107, 107, 1);
  --sentiment-positive: rgba(12, 183, 144, 1);
  --sentiment-negative: rgba(255, 107, 107, 1);
  --video-menu-bg: rgba(0, 0, 0, 0.8);
  --video-actions: rgba(255, 255, 255, 1);
  --video-action-hover: rgba(255, 255, 255, 0.12);
  --video-action-press: rgba(255, 255, 255, 0.22);
  --video-buffer: rgba(255, 255, 255, 0.76);
  --video-shade: rgba(255, 255, 255, 0.38);
  --video-alltime: rgba(102, 102, 102, 0.76);
  --error-type: rgba(241, 112, 123, 1);
  --error-area: rgba(208, 46, 0, 1);
  --error-area-tint: rgba(62, 29, 34, 1);
  --warning-area: rgba(62, 59, 29, 1);
  --shadow: rgba(0, 0, 0, 0.2);
}
