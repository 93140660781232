@import "variables";

@font-face {
    font-family: "Segoe UI";
    font-display: swap;
    src:
    url("#{$vi-font-path}segoeui.woff2") format("woff2"),
    url("#{$vi-font-path}segoeui.woff") format("woff"),
    url("#{$vi-font-path}segoeui.ttf") format("truetype"),
    url("#{$vi-font-path}segoeui.svg") format("svg"),
    url("#{$vi-font-path}segoeui.eot") format("eot"),
    local("Segoe UI");
    font-weight: 400;
  }
  @font-face {
    font-family: "Segoe UI";
    font-display: swap;
    src:
    url("#{$vi-font-path}segoeuil.woff2") format("woff2"),
    url("#{$vi-font-path}segoeuil.woff") format("woff"),
    url("#{$vi-font-path}segoeuil.ttf") format("truetype"),
    url("#{$vi-font-path}segoeuil.eot") format("eot"),
    url("#{$vi-font-path}segoeuil.svg") format("svg"),
    local("Segoe UI Light");
    font-weight: 100;
  }
  @font-face {
    font-family: "Segoe UI";
    font-display: swap;
    src:
    url("#{$vi-font-path}segoeuisl.woff2") format("woff2"),
    url("#{$vi-font-path}segoeuisl.woff") format("woff"),
    url("#{$vi-font-path}segoeuisl.ttf") format("truetype"),
    url("#{$vi-font-path}segoeuisl.eot") format("eot"),
    url("#{$vi-font-path}segoeuisl.svg") format("svg"),
    local("Segoe UI Semilight");
    font-weight: 200;
  }
  @font-face {
    font-family: "Segoe UI";
    font-display: swap;
    src:
    url("#{$vi-font-path}seguisb.woff2") format("woff2"),
    url("#{$vi-font-path}seguisb.woff") format("woff"),
    url("#{$vi-font-path}seguisb.ttf") format("truetype"),
    url("#{$vi-font-path}seguisb.eot") format("eot"),
    url("#{$vi-font-path}seguisb.svg") format("svg"),
    local("Segoe UI Semibold");
    font-weight: 600;
  }
  @font-face {
    font-family: "Segoe UI";
    font-display: swap;
    src:
    url("#{$vi-font-path}segoeuib.woff2") format("woff2"),
    url("#{$vi-font-path}segoeuib.woff") format("woff"),
    url("#{$vi-font-path}segoeuib.ttf") format("truetype"),
    url("#{$vi-font-path}segoeuib.eot") format("eot"),
    url("#{$vi-font-path}segoeuib.svg") format("svg"),
    local("Segoe UI Bold");
    font-weight: 700;
  }
