:root.vi.default {
  --highlight: rgba(43, 136, 216, 1);
  --primary: rgba(0, 120, 212, 1);
  --primary-hover: rgba(16, 110, 190, 1);
  --primary-press: rgba(0, 90, 158, 1);
  --primary-disable: rgba(0, 0, 0, 0.05);
  --secondary-fill: rgba(255, 255, 255, 1);
  --secondary-fill-hover: rgba(0, 0, 0, 0.05);
  --secondary-fill-press: rgba(0, 0, 0, 0.08);
  --secondary-stroke: rgba(0, 0, 0, 0.48);
  --component-fill: rgba(255, 255, 255, 1);
  --component-stroke: rgba(0, 0, 0, 0.62);
  --component-stroke-alt: rgba(0, 0, 0, 0.8);
  --play-status: rgba(189, 224, 255, 0.4);
  --play-status-alt: rgba(189, 224, 255, 1);
  --editor-highlight: rgba(43, 136, 216, 0.2);
  --editor-highlight-alt: rgba(43, 136, 216, 0.1);
  --thumb-overlay: rgba(0, 0, 0, 0.32);
  --header-actions: rgba(255, 255, 255, 1);
  --header-hovers: rgba(255, 255, 255, 0.38);
  --header-hovers-alt: rgba(255, 255, 255, 0.76);
  --header-bg: rgba(28, 28, 28, 1);
  --bg-dialog: rgba(0, 0, 0, 0.62);
  --dividers: rgba(0, 0, 0, 0.05);
  --dividers-alt: rgba(0, 0, 0, 0.08);
  --dividers-panel: rgba(0, 0, 0, 0.08);
  --bg-primary: rgba(255, 255, 255, 1);
  --bg-secondary: rgba(255, 255, 255, 1);
  --bg-tertiary: rgba(249, 249, 249, 1);
  --bg-access: rgba(255, 255, 255, 0.76);
  --type-primary: rgba(0, 0, 0, 0.8);
  --type-secondary: rgba(0, 0, 0, 0.62);
  --type-disabled: rgba(0, 0, 0, 0.38);
  --type-disabled-alt: rgba(0, 0, 0, 0.22);
  --white-placeholder: rgba(255, 255, 255, 0.76);
  --emotion-joy: rgba(204, 78, 0, 1);
  --emotion-fear: rgba(134, 28, 173, 1);
  --emotion-sadness: rgba(33, 59, 209, 1);
  --emotion-anger: rgba(235, 0, 27, 1);
  --sentiment-positive: rgba(18, 134, 110, 1);
  --sentiment-negative: rgba(235, 0, 27, 1);
  --video-menu-bg: rgba(0, 0, 0, 0.8);
  --video-actions: rgba(255, 255, 255, 1);
  --video-action-hover: rgba(255, 255, 255, 0.12);
  --video-action-press: rgba(255, 255, 255, 0.22);
  --video-buffer: rgba(255, 255, 255, 0.76);
  --video-shade: rgba(255, 255, 255, 0.38);
  --video-alltime: rgba(102, 102, 102, 0.76);
  --error-type: rgba(168, 0, 0, 1);
  --error-area: rgba(208, 46, 0, 1);
  --error-area-tint: rgba(253, 231, 233, 1);
  --warning-area: rgba(255, 244, 206, 1);
  --shadow: rgba(0, 0, 0, 0.1);
}
