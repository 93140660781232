//  ==========================================================================
//  Component: Password
//  ==========================================================================

#{$migration-password}
input.c-password[type="password"] {
    display: block;
    width: 276px;
    height: 36px;
    margin-top: 20px;
    padding: 7px 10px;
    box-sizing: border-box;
    border: 1px solid $color-foreground-60;
    outline: 0;
    background-color: $color-background;

    &.f-small {
        width: 88px;
    }

    &.f-flex {
        width: 100%;
        min-width: 88px;
        max-width: 400px;
    }

    &:hover {
        border-color: $color-foreground-80;
    }

    &:focus,
    &:active,
    &:focus {
        border-color: $color-accent;
    }

    &[disabled] {
        cursor: not-allowed;
        color: $color-foreground-20;
        border-color: $color-foreground-20;
    }

    &[readonly] {
        border: 1px solid $color-foreground-60;
        background-color: flatten-overlay($color-background, $color-foreground, 10%);
    }
}