$icon-font-family: 'videoindexer';
$font-family-vi: 'Segoe UI', 'Arial';
$grid-space: 12px;
$grid-row-tabs: 45px;
$grid-row-search: 36px;
$grid-row-footer: 35px;
$action-height: 32px;
$grid-left-padding: 20px;

$responsive-mini: 319px;
$responsive-small: 480px;
$responsive-medi: 640px;
$responsive-maxi: 640px;
$responsive-footer: 736px;
$responsive-media: 960px;
$responsive-medi-height: 600px;
$responsive-medi-customization-height: 900px;
$responsive-insights-truncate: 670px;
$responsive-media-height: 890px;
$responsive-gallery-input: 860px;
$responsive-internal-insights: 1488px;
$responsive-internal-edit-transcript: 1075px;
$responsive-small-edit-transcript: 380px;
$responsive-internal-insights-truncate: 1732px;
$responsive-hide-customization: 787px;
$responsive-customization: 1290px;
$responsive-customization-opened-menu: 1024px;
$responsive-hide-200-percent: 786px;
$input-border-radius: 2px;
$gallery-search-container-height: 192px;
$minified-gallery-search-container-height: 152px;
$heading-hidden-gallery-head-height: 176px;
$responsive-hide-sidemenu: 639px;
$responsive-settings: 900px;
$responsive-gallery-filter: 525px;
$responsive-brands-hide-user: 1500px;
$responsive-brands-hide-date: 768px;
$responsive-brands-hide-category: 427px;
$languages-minified-text: 1488px;
$max-mobile-width: 768px;
$min-mobile-height: 480px;
$responsive-with-textual-summary: 250px;
$responsive-sidemenu: 768px;
// accessibility variables
$zoom-400-height: 256px;
$zoom-400-width: 320px;
$zoom-200-width: 640px;
$zoom-200-height: 512px;
$zoom-150-width: 730px;
$zoom-125-width: 850px;
$zoom-250-width: 512px;
$zoom-300-width: 427px;

// zIndex - values can be from 0 to 1000 only!
$header-menu-zIndex: 1000;
$popup-zIndex: 999;
$sidebar-zIndex: 999;
$strip-zIndex: 998;
$footer-z-index: 101;
$header-zIndex: 92;
$upload-zIndex: 90;
$login-zIndex: 91;
$content-zIndex: 89;
$insights-header-zIndex: 70;

// Footer
$footer-height: 36px;
$footer-height-max-736: 48px;
$footer-height-max-670: 80px;
$footer-height-max-350: 144px;

$strip-height: 36px;
$header-height: 40px;
$side-menu-width-closed: 49px;
$side-menu-width-opened: 216px;
$side-menu-width-responsive: 232px;
$side-customization-sub-menu-width: 164px;
$side-edge-extensions-sub-menu-width: 212px;

:root {
  --header-strip-height: 0px;
  --header-height: calc(#{$header-height} + var(--header-strip-height));
  --side-menu-width: #{$side-menu-width-closed};
  --side-sub-menu-width: 0px;
  --unknown-people-search-height: 75px;
}

@media screen and (max-width: 573px) {
  :root {
    --unknown-people-search-height: 120px;
  }
}
