$tileHeaderRegularHeight: 50px; /* updates to tile header height should be reflected in tile.ts and dashboardVisualPluginService.ts */
$tileHeaderLargeHeight: 65px;
$tileHeaderSmallHeight: 42px;

$dashboardTileRegularPadding: 10px; /* updates to padding should be reflected in compositeTileModel.ts */
$dashboardTileSmallPaddingTop: 0;
$dashboardTileSmallPaddingBottom: 8px;

$tileHeaderRightMarginOnMobile: 23px;
$fontWeightSemiBold: 600;

@mixin visualCompositeTile() {
  top: $dashboardTileRegularPadding;
  left: $dashboardTileRegularPadding;
  right: $dashboardTileRegularPadding;
  bottom: $dashboardTileRegularPadding;
  font-family: $regularFontFamily;
  overflow: hidden;

  &.fullLayout {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .authoringDashboard &:not(.fullLayout),
  .is-dynamic-layout &:not(.fullLayout) {
    top: $dashboardTileSmallPaddingTop;
    bottom: $dashboardTileSmallPaddingBottom;
  }
}

@mixin visualDisplayTextSubDisplayText() {
  color: $neutralPrimaryColor;
  line-height: 1.3em;
  max-height: 2.6em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin visualSubDisplayTextDefaultSubtitle() {
  text-transform: uppercase;
  color: $neutralTertiaryColor;
}

@mixin visualTileHeader() {
  padding: 16px 10px 20px 10px;
  box-sizing: border-box;
  height: $tileHeaderRegularHeight;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
  transition: opacity 0.25s;

  &.largeHeader {
    height: $tileHeaderLargeHeight;
  }

  .authoringDashboard,
  .is-dynamic-layout {
    padding-top: 10px;
    /* $noflip */
    padding-left: 10px;
    height: $tileHeaderSmallHeight;
  }
}

@mixin visualTile() {
  top: $tileHeaderRegularHeight;
  color: $whiteColor;
  overflow: hidden;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;

  &.largeHeader {
    top: $tileHeaderLargeHeight;
  }

  .authoringDashboard &,
  .is-dynamic-layout & {
    top: $tileHeaderSmallHeight;
  }
}

@mixin tileTitleSubTitle() {
  .displayText {
    font-size: 12px;
    line-height: 13px;
    margin-bottom: -4px;
  }

  .subDisplayText {
    font-size: 11px;
    line-height: 10px;
  }
}

@mixin widget-header-sub-title() {
  color: var(--type-secondary);
  @include font-action-14-20();
}

@mixin webContentCorner() {
  .webContentCorner {
    border: ridge;
    top: -33px;
    left: -33px;
  }
}

@mixin focusOutline() {
  box-shadow: $focusBoxShadowDefinition $focusBoxShadowColor;
  outline: $focusOutlineDefinition $focusOutlineColor;
}

@mixin focusFluentOutline() {
  box-shadow: $focusBoxShadowDefinition $fluentThemeWhiteColor;
  outline: $focusOutlineDefinition $fluentThemeWhiteColor;
}

@mixin focusBorder() {
  box-shadow: $focusBoxShadowDefinition $focusBoxShadowColor;
  border: $focusOutlineDefinition $focusOutlineColor;
}

@mixin focusOutlineInset() {
  box-shadow: inset $focusBoxShadowDefinition $focusBoxShadowColor;
  outline: $focusOutlineDefinition $focusOutlineColor;
  outline-offset: -1px;
}

@mixin focusBorderInset() {
  box-shadow: inset $focusBoxShadowDefinition $focusBoxShadowColor;
  border: $focusOutlineDefinition $focusOutlineColor;
}

/* Useful for stopping elements from shifting when a visible border is added */
@mixin transparentBorder() {
  border: $focusOutlineDefinition transparent;

  // Remove the border in high contrast mode to avoid showing a white border (IE/Edge only)
  @media screen and (-ms-high-contrast: active) {
    border: none;
  }
}

@mixin fluentLink($linkColor: $fluentThemeBlackColor, $hoverColor: $fluentThemePrimaryColor) {
  cursor: pointer;
  &:link {
    color: $linkColor;
  }
  &:hover,
  &:active {
    color: $hoverColor;
  }
}

@mixin matProgressBarColor($color: var(--primary)) {
  .mdc-linear-progress__bar-inner {
    border-color: $color !important;
  }

  &.mat-mdc-progress-bar {
    .mdc-linear-progress__buffer-bar {
      background-color: var(--dividers-alt) !important;
    }
  }
}

@mixin font-title-h-1-20-28 {
  font-size: 20px;
  font-family: 'Segoe UI';
  font-display: swap;
  font-weight: normal;
  line-height: 28px;
}

@mixin font-title-h-1-alt-20-28 {
  font-size: 20px;
  font-family: 'Segoe UI';
  font-display: swap;
  font-weight: $fontWeightSemiBold;
  line-height: 28px;
}

@mixin font-subheader-18-24 {
  font-size: 18px;
  font-family: 'Segoe UI';
  font-display: swap;
  font-weight: $fontWeightSemiBold;
  line-height: 24px;
}

@mixin font-title-h-2-16-22 {
  font-size: 16px;
  font-family: 'Segoe UI';
  font-display: swap;
  font-weight: normal;
  line-height: 22px;
}

@mixin font-title-h-2-alt-16-22 {
  font-size: 16px;
  font-family: 'Segoe UI';
  font-display: swap;
  font-weight: $fontWeightSemiBold;
  line-height: 22px;
}

@mixin font-action-14-20 {
  font-size: 14px;
  font-family: 'Segoe UI';
  font-display: swap;
  font-weight: normal;
  line-height: 20px;
}

@mixin font-actions-alt-14-20 {
  font-size: 14px;
  font-family: 'Segoe UI';
  font-display: swap;
  font-weight: $fontWeightSemiBold;
  line-height: 20px;
}

@mixin font-body-13-18 {
  font-size: 13px;
  font-family: 'Segoe UI';
  font-display: swap;
  font-weight: normal;
  line-height: 18px;
}

@mixin font-body-alt-1-13-18 {
  font-size: 13px;
  font-family: 'Segoe UI';
  font-display: swap;
  font-weight: $fontWeightSemiBold;
  line-height: 18px;
}

@mixin font-body-alt-2-13-18 {
  font-size: 13px;
  font-family: 'Segoe UI';
  font-display: swap;
  font-weight: italic;
  line-height: 18px;
}

@mixin font-function-12-14 {
  font-size: 12px;
  font-family: 'Segoe UI';
  font-display: swap;
  font-weight: normal;
  line-height: 14px;
}

@mixin font-action-12-16 {
  font-size: 12px;
  font-family: 'Segoe UI';
  font-display: swap;
  font-weight: normal;
  line-height: 16px;
}

@mixin font-action-alt-12-16 {
  font-size: 12px;
  font-family: 'Segoe UI';
  font-display: swap;
  font-weight: $fontWeightSemiBold;
  line-height: 16px;
}

@mixin font-function-alt-12-16 {
  font-size: 12px;
  font-family: 'Segoe UI';
  font-display: swap;
  font-weight: $fontWeightSemiBold;
  line-height: 14px;
}

@mixin font-stamp-10-14 {
  font-size: 10px;
  font-family: 'Segoe UI';
  font-display: swap;
  font-weight: normal;
  line-height: 14px;
}

@mixin font-stamp-alt-10-14 {
  font-size: 10px;
  font-family: 'Segoe UI';
  font-display: swap;
  font-weight: bold;
  line-height: 14px;
}

@mixin text-area {
  border: 1px solid var(--component-stroke);
  color: var(--type-primary);
  background: var(--component-fill);
  border-radius: $input-border-radius;
  padding: 6px 8px;
  &:hover {
    border: 1px solid var(--component-stroke-alt);
  }
  &:focus {
    outline: none;
    border: 1px solid var(--primary);
  }
  &[disabled] {
    background-color: var(--primary-disable);
    border: 1px solid transparent;
    cursor: not-allowed;
    color: var(--type-disabled-alt);

    &::placeholder {
      color: var(--type-disabled);
    }
  }
  &.error {
    border: 1px solid var(--error-type);
  }
  &::placeholder {
    color: var(--type-secondary);
  }
}
