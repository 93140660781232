// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$vi-primary: mat.define-palette(mat.$indigo-palette);
$vi-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$vi-warn: mat.define-palette(mat.$red-palette);

// Create the theme object (a Sass map containing all of the palettes).
$vi-theme: mat.define-light-theme(
  (
    color: (
      primary: $vi-primary,
      accent: $vi-accent,
      warn: $vi-warn
    ),
    typography: mat.define-typography-config(),
    density: 0
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($vi-theme);

.cdk-overlay-container {
  font-family: $font-family-vi;
  .cdk-overlay-dark-backdrop {
    // TO-DO: return background for dialog after merging to angular
    background: $black-62;
  }
}

.cdk-overlay-pane {
  @media screen and (max-width: 768px) {
    overflow-y: auto;

    &.mobile-full-screen {
      width: 100vw !important;
      height: 100vh !important;
      max-width: none !important;
      .mat-mdc-dialog-container {
        border-radius: 0px;
      }
    }
  }

  @media screen and (-ms-high-contrast: white-on-black) {
    border: 2px solid;
    border-color: white;
  }

  @media screen and (-ms-high-contrast: black-on-white) {
    border: 2px solid;
    border-color: black;
  }
  &.dynamic-height {
    .mat-mdc-dialog-container {
      height: auto;
      max-height: 100vh;
    }

    &.upload-dialog-container {
      @media screen and (max-height: 790px) {
        min-height: auto !important;
        height: 740px !important;
      }
    }

    &.re-index-dialog-container {
      @media screen and (max-height: 790px) {
        min-height: auto !important;
        height: 740px !important;
      }
    }

    &.create-preset-dialog-container, &.edit-preset-dialog-container {
      .dialog-container {
        overflow: hidden;
      }
    }
  }
}

.cdk-global-overlay-wrapper {
  @media screen and (max-width: 1024px) {
    overflow-y: scroll;
  }

  @media screen and (max-width: 768px) {
    &.mobile-full-screen {
      overflow-y: hidden;
    }
  }

  &.bg-background {
    margin-top: 40px;
    padding-bottom: 40px;
    background: var(--bg-tertiary);
    @media screen and (max-width: $zoom-300-width) {
      margin-top: 30px;
      padding-bottom: 30px;
    }
  }
}

.cdk-global-scrollblock {
  overflow: hidden;
}

.mat-mdc-progress-bar {
  --mdc-linear-progress-track-height: 4px;
  --mdc-linear-progress-active-indicator-height: 4px;
}

.mat-mdc-dialog-container {
  overflow: hidden !important;
  max-height: 600px;
  box-shadow: 0px 24px 48px var(--shadow);
  background: var(--bg-primary);
  color: var(--type-primary);
  padding: 24px;

  .mdc-dialog__container .mat-mdc-dialog-surface {
    background-color: transparent;
    box-shadow: none;
    border-radius: 0px;
  }
}

.mat-mdc-dialog-container .mat-mdc-dialog-content[mat-dialog-content] {
  font-family: $font-family-vi;
  color: var(--type-primary);
}

.mdc-data-table__header-cell,
.mdc-data-table__cell {
  font-family: $font-family-vi;
  letter-spacing: normal;
}

.about-dialog-container .mat-mdc-dialog-container {
  padding: 0;
  overflow: hidden;
}

.preview-dialog-container {
  overflow-x: hidden;
  overflow-y: auto;
}

.mat-mdc-table {
  font-family: $font-family-vi;
  padding-bottom: 45px;
  --mat-table-background-color: var(--bg-primary);
}

.mat-sort {
  ::ng-deep .mat-sort-header-arrow[style] {
    // Hide default arrow stem
    .mat-sort-header-stem {
      display: none;
    }
    .mat-sort-header-indicator {
      opacity: 1;
      color: black;
      font-weight: bold;

      // Hide default arrow as its composed of left, right and middle
      .mat-sort-header-pointer-left,
      .mat-sort-header-pointer-right,
      .mat-sort-header-pointer-middle {
        display: none;
      }
    }
  }

  // My custom ascending arrow
  [aria-sort='ascending'] {
    ::ng-deep .mat-sort-header-arrow {
      .mat-sort-header-indicator {
        transform: none !important;
        &::before {
          font: normal normal normal 1.1rem/1 videoindexer;
          content: '\ee68';
          top: 1px;
          position: absolute;
          color: var(--type-primary);
          font-size: 12px;
        }
      }
    }
  }

  // My custom descending arrow
  [aria-sort='descending'] {
    ::ng-deep .mat-sort-header-arrow {
      .mat-sort-header-indicator {
        transform: none !important;
        &::before {
          font: normal normal normal 1.1rem/1 videoindexer;
          content: '\ee69';
          top: 1px;
          position: absolute;
          color: var(--type-primary);
          font-size: 12px;
        }
      }
    }
  }
}
